<template>
  <b-card title="Upload Berkas Penagihan">
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="6">
            <form-file
              v-model="file"
              label="File Permohonan"
            />
            <form-file
              v-model="file"
              label="File Invoice"
            />
            <form-file
              v-model="file"
              label="File Kwitansi"
            />
            <form-file
              v-model="file"
              label="File Efaktur Pajak"
            />
            <form-file
              v-model="file"
              label="File PKP"
            />
            <form-file
              v-model="file"
              label="File NPWP"
            />
            <form-file
              v-model="file"
              label="File BAPP"
            />
          </b-col>
          <b-col cols="6">
            <form-file
              v-model="file"
              label="File Bukti Service"
            />
            <form-file
              v-model="file"
              label="File Perjanjian"
            />
            <form-file
              v-model="file"
              label="File Amandemen"
            />
            <form-file
              v-model="file"
              label="File SPB"
            />
            <form-file
              v-model="file"
              label="File BA RIKMATEK"
            />
            <form-file
              v-model="file"
              label="File BAST"
            />
            <form-file
              v-model="file"
              label="File BA Denda"
            />
          </b-col>
          <b-col cols="12">
            <hr>
            <b-form-group
              label-cols-md="0"
              label=""
            >
              <b-button
                class="mr-1"
                size="sm"
                variant="primary"
                type="button"
                @click.prevent="validationForm"
              >
                <span v-if="loading">
                  <b-spinner small />
                  Loading...
                </span>
                <span v-else>Simpan</span>
              </b-button>
              <b-button
                class="mr-1"
                size="sm"
                variant="warning"
                type="button"
                @click.prevent="$router.go(-1)"
              >
                <span>Batal</span>
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BForm, BRow, BCol, BTable, BLink, BBadge, BFormGroup, BFormSelect, BFormInput, BButton, BSpinner} from 'bootstrap-vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormFile from '@/views/base/form/FormFile.vue'
import FormDate from '@/views/base/form/FormDate.vue'
import moment from 'moment'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BForm,
    BCard,
    BTable,
    BLink,
    BBadge, 
    BFormGroup,
    BFormSelect,
    BFormInput,
    baseSelect,
    FormInput,
    FormVSelect,
    BButton,
    BRow, 
    BCol,
    FormFile,
    FormDate,
    BSpinner,
  },
  data() {
    return {
      dataurl:"/vehicle-mutation",
      baseroute:"mutation",
      title:"Mutasi",
      file:null,
    }
  },
  methods: {
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.successSubmit()
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      this.$router.go(-1)
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
    
    
  }
}
</script>